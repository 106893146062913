.alert-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .alert-dialog-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .alert-dialog-header {
    margin-bottom: 10px;
  }
  
  .alert-dialog-title {
    margin: 0;
    font-size: 1.5em;
  }
  
  .alert-dialog-description {
    margin: 0;
  }